import React, { Component } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import "./App.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

class App extends Component {
  state = { events: [{"start":"2024-04-11 00:00:00","end":"2024-04-11 02:45:00","title":"meet"},{"start":"2024-04-11 03:00:00","end":"2024-04-11 03:45:00","title":"meet"},{"start":"2024-04-13 00:00:00","end":"2024-04-13 02:45:00","title":"meet 2"}] }
 
  componentDidMount() {
    const eventsFormatted = this.state.events.map(event => ({
      ...event,
      start: new Date(event.start),
      end: new Date(event.end)
    }));
    
    this.setState({ events: eventsFormatted });
  }

  render() {
    return (
      <div className="App">
        <Calendar
          localizer={localizer}
          defaultDate={new Date()}
          defaultView="month"
          events={this.state.events}
          style={{ height: "100vh" }}
        />
      </div>
    );
  }
}

export default App;
